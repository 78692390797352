import BasePage from '../../../../../components/table_page';
import request from '../../../../../utils/request';
import Form from './form';

export default {
  name: 'blending_policy_management',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
  },
  created() {
    this.searchFormData.enableStatus = '009';
    this.getConfigList('tpm_blending_policy_management_list');
  },
  methods: {
    // 判断是否有‘enableStatus’字段
    hasEnableStatus() {
      const index = this.searchList.findIndex((item) => item.field === 'enableStatus');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasEnableStatus()) {
        this.formData.enableStatus = this.searchFormData.enableStatus || null;
      } else if (this.searchFormData.enableStatus) {
        delete this.searchFormData.enableStatus;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasEnableStatus()) {
        this.searchFormData.enableStatus = '009';
        this.formData.enableStatus = this.searchFormData.enableStatus;
      }
      return true;
    },
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      const { enableStatus } = row;
      if (code === 'lose_efficacy' && enableStatus === '003') {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      }
      if (val.code === 'lose_efficacy') {
        this.$confirm('是否确认当前政策失效?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            request.post('/tpm/tpmTastingPolicy/invalidTastingPolicy', { id: row.id }).then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '操作成功',
                });
                this.getList();
              }
            });
          });
      }
    },
  },
};
