import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import ProductList from './product_list.vue';

formCreate.component('ProductList', ProductList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tpm_blending_policy_management_form',
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          },
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          },
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          },
        }],
      },
    };
  },
  methods: {

    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'productName') { // 选择本品酒
        v.props = {
          ...v.props,
          params: {
            functionCode: 'product_list',
            paramData: {
              enableStatus: '009',
              isShelf: 'Y',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'productNameGift') { // 选择配赠酒
        v.props = {
          ...v.props,
          params: {
            functionCode: 'product_list',
            paramData: {
              enableStatus: '009',
              isShelf: 'Y',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'rule') { // 配赠比例
        v.props = {
          ...v.props,
          min: 0,
          precision: 4,
          controls: false,
        };
      }
      if (v.field === 'datePicker') { // 政策时间
        v.props = {
          ...v.props,
          type: 'datetimerange',
          pickerOptions: this.pickerOptions,
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: ['00:00:00', '23:59:59'],
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      const productName = this.getRule('productName');
      const productNameGift = this.getRule('productNameGift');
      // 本品酒
      productName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            productName: e[0].productName,
            productCode: e[0].productCode,
          });
        } else {
          this.setValue({
            productName: null,
            productCode: null,
          });
        }
      };

      // 配赠酒
      productNameGift.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            productNameGift: e[0].productName,
            productCodeGift: e[0].productCode,
          });
        } else {
          this.setValue({
            productNameGift: null,
            productCodeGift: null,
          });
        }
      };

      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .post('/tpm/tpmTastingPolicy/getTastingPolicyDetail', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const { result } = res;
              result.datePicker = [result.beginTime, result.endTime];
              this.setValue(result);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/tpm/tpmTastingPolicy/addTastingPolicy';
        const params = formData;
        params.beginTime = formData.datePicker && formData.datePicker.length > 0
          ? formData.datePicker[0]
          : null;
        params.endTime = formData.datePicker && formData.datePicker.length > 0
          ? formData.datePicker[1]
          : null;
        delete params.datePicker;
        delete params.title1;
        if (this.formConfig.code === 'edit') {
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
